import React, { useEffect, useState } from "react";

import { ReactComponent as AlertIcon } from "../../assets/icons/alert_icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check_icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info_icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error_icon.svg";

const DISMISS_TIME = 3; //5s

export const ToastType = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    INFO: "info",
}

let addList = () => { }

const Toast = ({ autoDelete = true, }) => {
    const [list, setList] = useState([]);

    addList = (val) => {
        setList([...list, val]);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && list.length) {
                removeNotification(list[0].id);
            }
        }, DISMISS_TIME * 1000);

        return () => {
            clearInterval(interval);
        }
    }, [list]);

    const removeNotification = (id) => {
        const index = list.findIndex(n => n.id === id);
        list.splice(index, 1);
        setList([...list]);
    }

    const getType = (type) => {
        let toastProperties = {};
        switch (type) {
            case ToastType.SUCCESS:
                toastProperties = {
                    color: '#0ee38a',
                    icon: <CheckIcon />
                }
                break;
            case ToastType.ERROR:
                toastProperties = {
                    color: '#d9534f',
                    icon: <ErrorIcon />
                }
                break;
            case ToastType.INFO:
                toastProperties = {
                    color: '#5bc0de',
                    icon: <InfoIcon />
                }
                break;
            case ToastType.WARNING:
                toastProperties = {
                    color: '#f0ad4e',
                    icon: <AlertIcon />
                }
                break;
        }
        return toastProperties;
    }

    return (
        <div className="notification-container">
            {list.map((t) => {
                let type = getType(t.type);
                return (
                    <div key={t.id} className="notification" style={{ backgroundColor: type.color }}>
                        <div className="notification-image">
                            {type.icon}
                        </div>
                        <div className="notification-content">
                            <p className="notification-title">
                                {t.title}
                            </p>
                            <p className="notification-message">
                                {t.message}
                            </p>
                        </div>
                        {/* <a className="close" href="#" onClick={() => removeNotification(t.id)} /> */}
                    </div>
                )
            })}
        </div>
    )
}

export const createNotification = (title = "", message = "", type = ToastType.SUCCESS) => {
    let notification = {
        id: Date.now(),
        title,
        message,
        type
    };
    addList(notification);
}

export default Toast;