import React, { useState } from 'react';


const Autocomplete = ({
    className = "", list = [], name = "", disable = false, group = false,
    onChangeText = () => { }, onChange = () => { }, onReject = () => { }
}) => {
    const [isFocus, setFocus] = useState(false);
    const [text, setText] = useState("");

    return (
        <div className={"autocomplete " + className}>
            <input
                type="text"
                className="input-text form-control"
                placeholder={name}
                onChange={({ target: { value } }) => {
                    if (value != "") {
                        onChangeText(value.trim().replace("  ", " "));
                    }
                    setText(value);
                    onReject();
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setTimeout(() => setFocus(false), 100)}
                disabled={disable}
                value={text}
            />

            {isFocus &&
                <div className="dropdown-list">
                    {list?.map((item) =>
                        <button key={item["@id"]} className="dropdown-item"
                            onClick={() => {
                                setText(item.name);
                                onChange(item);
                            }}>
                            {item.name}
                        </button>
                    )}
                </div>
            }
        </div>
    )
}
export default Autocomplete;