import React from "react";

const IntroductionPart = ({ homeImage }) => {

  return (
    <div className="introductionPart bgColor">
      <img className="image" src={homeImage} alt="" />
      {/* <div className="title w-100">
        Notre simulateur de prix
      </div>
      <div className="description w-100">
        Découvrez nos marques acceptées et nos prix de rachat
      </div>
      <div className="storebg">
        <div className="storebgimg">
          <img src={require("../../../assets/images/storebgimg.png")} alt="" height="100%" />
        </div>
        <div className="storebgimg2">
          <img src={require("../../../assets/images/storebgimg2.png")} alt="" height="100%" />
        </div>
        <div className="logostorebigger"/>
      </div> */}
    </div >

  );
}

export default IntroductionPart;
