import React, { } from "react";

import Button from "../../components/Button";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/remove_icon.svg";


const OrdersPart = ({ orders = [], next = () => { }, prev = () => { }, removeOrder = (i) => { } }) => {
    return (
        <div className="orderPart">
            <div className="orders-table">
                <table >
                    <thead>
                        <tr>
                            <th className="col1">#</th>
                            <th className="col3">Marque</th>
                            <th className="col2">Catégorie</th>
                            <th className="col2">Matière</th>
                            <th className="col2">Couleur</th>
                            <th className="col1">Taille</th>
                            <th className="col3">Etat</th>
                            <th className="col2">Le prix</th>
                            <th className="col0"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((val, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{val.brand.name}</td>
                                <td>{val.category.name + " " + val.category.children.name}</td>
                                <td>{val.material.name}</td>
                                <td>{val.color.name}</td>
                                <td>{val.size.name}</td>
                                <td>{val.state.name}</td>
                                <td>{val.price} €</td>
                                <td>
                                    <button className="btnOrderRemove" onClick={() => removeOrder(index)}>
                                        <RemoveIcon className="btnOrderRemove-Icon" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {!orders.length && (
                            <tr>
                                <td colSpan={10} className="text-center align-middle" style={{ height: 160 }}>
                                    Il n'y a rien dans votre panier.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="btnGroup d-flex flex-wrap justify-content-center mx-auto">
                {orders.length > 0 &&
                    <Button className="btn-green mr-1"
                        title={<>Je confirme<br />mon panier</>}
                        onClick={next} />
                }

                <Button className="btn-blue"
                    title={<>Ajouter un<br />nouvel article</>}
                    onClick={prev} />
            </div>
        </div>
    )
}

export default OrdersPart;