
import React, { useState } from "react";

import { ReactComponent as MenuIcon } from "../../../assets/icons/menu_icon.svg";
import { ReactComponent as MenuCloseIcon } from "../../../assets/icons/menu-close_icon.svg";

const HeaderPart = ({ totalOrders = 0,
  onClickHome = () => { }, onClickOrder = () => { } }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onChangeSearch = ({ target: { value } }) => setSearchText(value);

  return (
    <>
      <div className="headerPart d-flex flex-row align-items-center justify-content-between w-100">
        <div className="d-flex flex-row align-items-center ">
          <div className="pe-auto" onClick={() => setOpenMenu(!openMenu)}>
            <MenuIcon className="menuIcon mx-md" />
          </div>
          <div className="logostore" onClick={onClickHome} />
        </div>
        <div className="header-title d-flex align-item-right">
          {/* <input className="input-search form-control border-0 border-bottom border-dark border-2 rounded-0"
            type="search" placeholder="RECHERCHER"
            value={searchText} onChange={onChangeSearch} /> */}
          <div className="d-flex">
            <a href="#" className="header-link" onClick={onClickOrder}>PANIER ({totalOrders})</a>
          </div>
        </div>
      </div>
      <div className={"menu-bg " + (openMenu ? "menu-bg-open" : "")} >
        <div className={"menu " + (openMenu ? "menu-open" : "")}>
          <div className="menu-close-icon">
            <div onClick={() => setOpenMenu(false)}>
              <MenuCloseIcon className="h-100" />
            </div>
          </div>
          {/* <input className="input-search form-control border-0 border-bottom border-dark border-2 rounded-0"
            type="search" placeholder="RECHERCHER"
            value={searchText} onChange={onChangeSearch} /> */}
          <div className="nav-group">
            <a href="#" className="nav-link" onClick={() => {
              setOpenMenu(false);
              onClickOrder();
            }}>
              PANIER ({totalOrders})
            </a>
          </div>
        </div>
        <div className="col" onClick={() => setOpenMenu(false)} />
      </div>
    </>
  );
}

export default HeaderPart;

