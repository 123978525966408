import React from "react";

const Modal = ({ onClose = () => { }, visible = false, children }) => {
    return (
        <div className={"popupBg " + (visible ? "popupBg-visible" : "")}>
            <button className="popupClose" onClick={onClose}>Fermer</button>
            <div className={"popupContent " + (visible ? "popupContent-visible" : "")} onClick={(e)=>{e.preventDefault()}}>
                {children}
            </div>
        </div>
    )
}

export default Modal;