import React, { useState } from 'react';

import { ReactComponent as DropDownIcon } from "../../assets/icons/dropdown_icon.svg";

const Select = ({ className = "", list = [], defaultVal = null, name = "", disable = false, group = false, onChange = (val = null) => { }, onReject = () => { } }) => {
    const [isFocus, setFocus] = useState(false);

    return (
        <div className={"selectinput " + className}>
            <select
                className="selectState form-control"
                defaultValue={defaultVal ? null : name}
                onChange={({ target: { value } }) => {
                    if (value === name) {
                        onReject();
                    } else {
                        if (group) {
                            list.forEach((val) => {
                                val.children?.forEach(c => {
                                    if (c["@id"] === value) {
                                        onChange(val, c);
                                    }
                                });
                            });
                        } else {
                            list.forEach((val) => {
                                if (val["@id"] === value) {
                                    onChange(val);
                                }
                            });
                        }
                    }
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                disabled={disable}
            >
                {defaultVal ? undefined : <option value={name}>{name}</option>}
                {!group &&
                    list.map((item) =>
                        <option key={item["@id"]} value={item["@id"]}
                            selected={defaultVal?.id == item["@id"]}>
                            {item.name}
                        </option>
                    )
                }
                {group &&
                    list.map((item) =>
                        <optgroup key={item["@id"]} label={item.name}>
                            {item.children?.map(val =>
                                <option key={val["@id"]} value={val["@id"]}                           >
                                    {val.name}
                                </option>
                            )}
                        </optgroup>
                    )
                }
            </select>
            <DropDownIcon className={"dropdown_icon " + (isFocus ? "dropdown_icon_active" : "")} />
        </div>
    )
}
export default Select;