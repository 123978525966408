import "../assets/css/screen-full.css";
import "../assets/css/screen-normal.css";
import "../assets/css/screen-mobile.css";
import "../assets/css/toast.css";

import React, { useEffect, useState } from 'react';

import { checkToken, getDataFilter } from "../service/api";
import HeaderPart from './parts/headerPart';
import IntroductionPart from './parts/introductionPart';
import GetQrPart from './parts/getQrPart';
import FilterPart from "./parts/filterPart";
import InformationPart from "./parts/informationPart";
import FooterPart from "./parts/footerPart";
import PricePart from "./parts/pricePart";
import ResultSuccessPart from "./parts/resultSuccessPart";
import ResultFailPart from "./parts/resultFailPart";
import OrdersPart from "./parts/ordersPart";
import Toast, { createNotification } from "./components/Toast";

const ORDERS_KEYSTORE = "ORDERS_KEYSTORE";

let resultTmp = {}

const Screen = ({ homeImage }) => {
    const [orders, setOrders] = useState([]);
    const [indexView, setIndexView] = useState(0);
    const [result, setResult] = useState({});
    const [originData, setOriginData] = useState({
        newBrands: [], newCategories: [], newMaterials: [], newColors: [], newSizes: [], newStatus: []
    });

    useEffect(() => {
        checkToken().then(async () => {
            const [newBrands, newMaterials, newColors, newStatus] = await getDataFilter();

            setOriginData({
                newBrands: newBrands["hydra:member"],
                newMaterials: newMaterials["hydra:member"].map(val => ({ ...val, name: val.material })),
                newColors: newColors["hydra:member"].map(val => ({ ...val, name: val.color })),
                newStatus: newStatus["hydra:member"],
            });
        });
        setOrders(loadOrdersFromLocal());
    }, []);

    const loadOrdersFromLocal = () => {
        const ordersStr = localStorage.getItem(ORDERS_KEYSTORE);
        if (ordersStr) {
            let orderTmp = JSON.parse(ordersStr);
            return orderTmp;
        }
        return [];
    }

    const addToOrders = () => {
        const ordersLocal = loadOrdersFromLocal();
        ordersLocal.push(result);
        localStorage.setItem(ORDERS_KEYSTORE, JSON.stringify(ordersLocal));
        setOrders(ordersLocal);
        createNotification("Succès", "Ajouté au panier");
    }

    const removeOrder = (index) => {
        const ordersLocal = loadOrdersFromLocal();
        ordersLocal.splice(index, 1);
        localStorage.setItem(ORDERS_KEYSTORE, JSON.stringify(ordersLocal));
        setOrders(ordersLocal);
    }

    const removeAllOrder = () => {
        localStorage.setItem(ORDERS_KEYSTORE, "[]");
        setOrders([]);
    }


    const scriptView = [
        <>
            <IntroductionPart homeImage={homeImage} />
            <FilterPart originData={originData}
                next={(rs) => {
                    setResult(rs);
                    setIndexView(1);
                }} fail={() => {
                    window.scrollTo(0, 0);
                    setIndexView(4);
                }} />
        </>, // 0
        <>
            <IntroductionPart homeImage={homeImage} />
            <PricePart
                result={result}
                addToOrders={addToOrders}
                next={() => {
                    window.scrollTo(0, 0);
                    setIndexView(5);
                }}
                prev={() => setIndexView(0)}
            />
        </>, // 1
        <>
            <GetQrPart viewLogo={true} originData={orders}
                next={(newResult) => {
                    resultTmp = newResult;
                    window.scrollTo(0, 0);
                    setIndexView(3);
                    removeAllOrder();
                }} />
        </>, // 2
        <>
            <ResultSuccessPart orders={resultTmp} next={() => {
                window.scrollTo(0, 0);
                setIndexView(0);
                setResult({});
            }} />
        </>, // 3
        <>
            <ResultFailPart next={() => {
                window.scrollTo(0, 0);
                setIndexView(0);
                setResult({});
            }} />
        </>, // 4
        <>
            <OrdersPart orders={orders}
                next={() => {
                    setIndexView(2);
                }}
                prev={() => {
                    setIndexView(0);
                }}
                removeOrder={removeOrder} />
        </>, // 5
    ];

    return (
        <div className="homePart">
            <Toast />
            <HeaderPart
                totalOrders={orders.length}
                onClickOrder={() => {
                    setIndexView(5);
                }}
                onClickHome={() => {
                    window.scrollTo(0, 0);
                    setIndexView(0);
                }} />
            {scriptView[indexView]}
            <FooterPart />
        </div>
    )
}

export default Screen;