import axios from "axios";
import Cookies from "universal-cookie";

import config from "../config.json";
import { createNotification, ToastType } from "../screen/components/Toast";

const API = axios.create({
    baseURL: config.host_api,
    timeout: 30000
});

API.interceptors.request.use(async (req) => {
    const cookies = new Cookies();
    const token = cookies.get("TOKEN");
    if (token) {
        req.headers["Authorization"] = "Bearer " + token;
    }
    req.headers["Content-Type"] = "application/json";
    return req;
});

API.interceptors.response.use((res) => {
    return res.data;
}, (err) => {
    throw err;
});


const checkToken = async () => {
    const cookies = new Cookies();
    const token = cookies.get("TOKEN");
    if (token) {
        return token;
    }
    try {
        const data = await API.post("authentication", {
            email: config.email,
            password: config.password
        });
        if (data) {
            const expires = new Date();
            expires.setHours(expires.getHours() + 1);
            cookies.set("TOKEN", data.token, {
                expires, secure: true
            });
            return data.token;
        }
    } catch (e) {
        createNotification("Erreur", "Impossible de se connecter au serveur", ToastType.ERROR);
    }
    return "";
}

const getDataFilter = async () => {
    const data = await Promise.all([
        API.get("brands"),
        API.get("materials"),
        API.get("colors"),
        API.get("states"),
    ]);
    return data;
}

const getSizesByCategory = (categoryId) => {
    return API.get("sizes", {
        params: {
            category: categoryId
        }
    });
}
const getMyInfo = () => {
    return API.get("users/me");
}

const searchBrandByName = (text) => {
    return API.get("brands", {
        params: {
            name: text
        }
    });
}

const estimate = (param) => {
    return API.get("arguses", {
        params: param
    });
}

const getQRcode = (data) => {
    return API.post("argus/generate", data);
}

export {
    getDataFilter,
    getSizesByCategory,
    searchBrandByName,
    estimate,
    getQRcode,
    checkToken,
    getMyInfo
}
