import React from "react";


import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook_icon.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/instagram_icon.svg";
import { ReactComponent as YouTubeIcon } from "../../../assets/icons/youtube_icon.svg";

const FooterPart = () => {
    return (
        <div className="footerPart">
            <div className="d-flex flex-wrap justify-content-around">
                <div className="subscribe">
                </div>
                <div className="moreinformation d-flex">
                    <div className="info-col">
                    </div>
                    <div className="info-col">
                        <div className="coltitle">A propos</div>
                        <a href="https://thesecondlife.io/support/" target="_blank">
                            <div className="colitem">Contact</div>
                        </a>
                        <a href="https://thesecondlife.io/la-seconde-main-au-coeur-de-votre-boutique/cgv/" target="_blank">
                            <div className="colitem">CGV</div>
                        </a>
                        <a href="https://thesecondlife.io/politique-de-confidentialite/" target="_blank">
                            <div className="colitem">Politique de confidentialité</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="copyrighttext">The Second Life Copyright 2021</div>
        </div>
    )
}

export default FooterPart;