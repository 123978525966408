import React, { useEffect, useState } from 'react';

import Select from '../../components/Select';
import background from "../../../assets/images/greensunbgimg_right.png";
import { estimate, getSizesByCategory, searchBrandByName } from '../../../service/api';
import Autocomplete from '../../components/Autocomplete';

let result = {}

const resultDefault = {
    brand: undefined,
    size: undefined,
    color: undefined,
    material: undefined,
    category: undefined,
    state: undefined,
}

const DEFAULT_STATE = { id: "/states/63", state: "WITHOUT-LABEL", name: "Sans étiquette" };

let delayTimer;

const FilterPart = ({ next = (obj) => { }, fail = () => { }, originData }) => {
    const [step, setStep] = useState(1);
    const [listSize, setListSize] = useState();
    const [listBrand, setListBrand] = useState([]);
    const [listCategories, setListCategories] = useState();
    const [btnDisable, setBtnDisable] = useState(false);

    useEffect(() => {
        result = resultDefault
    }, []);

    useEffect(() => {
        setListBrand(originData.newBrands);
        result.state = DEFAULT_STATE;
    }, [originData.newBrands]);

    const findCategoriesByBrand = (brand) => {
        setListCategories(brand.categories);
    }

    const findSizesByCategory = (category) => {
        getSizesByCategory(category["@id"]).then(data => {
            let origin = data["hydra:member"];
            origin = origin.map(val => ({
                ...val,
                name: val.size,
            }))
            setListSize(origin);
        }).catch(() => {
            setListSize(null);
        });
    }

    const searchBrand = (text) => {
        if (delayTimer) {
            clearTimeout(delayTimer);
        }
        delayTimer = setTimeout(() => {
            searchBrandByName(text).then(data => {
                setListBrand(data["hydra:member"]);
            }).catch(() => {
                setListBrand(originData.newBrands);
            });
        }, 500);
    }

    const submit = () => {
        setBtnDisable(true);
        const param = {
            brand: result.brand.name,
            size: result.size.name,
            color: result.color.name,
            material: result.material.name,
            category: result.category.name + "." + result.category.children.name,
            state: result.state.state
        }
        estimate(param).then(data => {
            if (data && data["hydra:member"].length > 0 &&
                data["hydra:member"][0].buy === true) {
                next({ ...result, price: data["hydra:member"][0].buyingPrice });
                return;
            }
            fail();
        }).catch(() => fail());
    }

    return (
        <div className="filterPart d-flex flex-column bgImage bgImage-right" >
            <div className="title mt-5">Estimez votre garde-robe </div>
            <div className="titlesecondary">grâce à notre argus</div>
            <div className="description">Entrez les caractéristiques du vêtement que vous souhaitez vendre et obtenez l’estimation de son prix de reprise sous forme de QR Code.</div>

            <div className="d-flex flex-row flex-wrap mt-5 align-self-center justify-content-center">
                <Autocomplete list={listBrand} name="Marque" disable={step < 1}
                    onChange={(val) => {
                        result.brand = val;
                        findCategoriesByBrand(val);
                        result.brand.categories = undefined;
                        setStep(2);
                    }}
                    onChangeText={searchBrand}
                    onReject={() => {
                        setStep(1);
                    }} />
                <Select list={listCategories} name="Catégorie" disable={step < 2} group={true}
                    onChange={(val, val2) => {
                        result.category = { ...val, children: val2 };
                        findSizesByCategory(val2);
                        setStep(3);
                    }}
                    onReject={() => {
                        setStep(2);
                    }} />
                <Select list={listSize} name="Taille" disable={step < 3}
                    onChange={(val) => {
                        result.size = val;
                        setStep(4);
                    }}
                    onReject={() => {
                        setStep(3);
                    }} />
                <Select list={originData.newMaterials} name="Matière" disable={step < 4}
                    onChange={(val) => {
                        result.material = val;
                        setStep(5);
                    }}
                    onReject={() => {
                        setStep(4);
                    }} />
                <Select list={originData.newColors} name="Couleur" disable={step < 5}
                    onChange={(val) => {
                        result.color = val;
                        setStep(6);
                    }}
                    onReject={() => {
                        setStep(5);
                    }} />
                <Select list={originData.newStatus} name="Etat" disable={step < 6} defaultVal={DEFAULT_STATE}
                    onChange={(val) => {
                        result.state = val;
                        setStep(6);
                    }}
                    onReject={() => {
                        setStep(5);
                    }} />
            </div>
            <div className="submitbutton">
                <button className="btn rounded-2" disabled={step < 6 || btnDisable} onClick={submit}>
                    ESTIMER
                </button>
            </div>
        </div>
    )
}

export default FilterPart;
