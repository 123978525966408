import React from "react";

import background from "../../../assets/images/greensunbgimg.png";
import Button from "../../components/Button";


const ResultFailPart = ({ next = () => { } }) => {

    return (
        <div className="resultFailPart bgColor bgImage bgImage-left">
            <div className="logostorebigger" />
            <div className="message">
                Nous sommes désolé, cet article n’est pas
                encore repris par notre argus.
            </div>
            <div className="description">
                Essayez avec un autre article !
            </div>
            <div className="btnGroup d-flex justify-content-center">
                <Button className="btn-blue" title={<>Je recommence</>}
                    onClick={next} />
            </div>

        </div>
    )
}

export default ResultFailPart;