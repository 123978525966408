import React, { useState } from "react";

import background from "../../../assets/images/greensunbgimg.png";
import { getQRcode } from "../../../service/api";
import Button from "../../components/Button";
import { createNotification, ToastType } from "../../components/Toast";


const GerQrPart = ({ originData = [], next = (data) => { } }) => {
    const [email, setEmail] = useState("");
    const [btnDisable, setBtnDisable] = useState(true);

    const getQrCodeByOrder = async (data) => {
        const order = {
            brand: data.brand.name,
            category: data.category.name + "." + data.category.children.name,
            material: data.material.name,
            size: data.size.name,
            color: data.color.name,
            state: data.state.state,
            email: email,
        }
        let res = await getQRcode(order);
        if (res) {
            const qrCode = "data:image/jpeg;base64, " + res.qrcode;
            order.state = data.state.name;
            order.price = data.price;
            order.qrCode = qrCode;
        }
        return order;
    }

    const submit = () => {
        if (checkMail() && next) {
            setBtnDisable(true);
            Promise.all(originData.map((o) => getQrCodeByOrder(o)))
                .then((orders) => {
                    next(orders);
                }).catch(e => {
                    setBtnDisable(false);
                    createNotification("", "Votre adresse mail n'est pas correct", ToastType.ERROR);
                })
        }
    }

    const checkMail = () => {
        const arr = email.match(/\S+@\S+\.\S+/);
        if (arr && arr[0].length === email.length) {
            return true;
        }
        return false;
    }

    const validEmail = checkMail();

    return (
        <div className="getQrPart bgColor bgImage bgImage-left" style={{
            backgroundImage: `url(${background})`
        }}>
            <div className="logostorebigger"/>
            <div className="title">Vous y êtes presque ! </div>
            <div className="description">
                Entrez votre adresse mail pour recevoir le QR code correspondant à l’article ainsi que les 3 étapes pour recevoir votre carte cadeaux.
            </div>

            <input className="input_mail form-control" type="email" placeholder="hello@gmail.fr" value={email}
                onChange={({ target: { value } }) => setEmail(value)} />
            <div className="btnGroup d-flex justify-content-center">
                <Button className="btn-green" title={<>Recevoir mon<br />code QR</>} disabled={!(validEmail || !btnDisable)}
                    onClick={submit} />
            </div>
        </div>
    )
}

export default GerQrPart;